import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowRight, ArrowDown } from "lucide-react";
import box2 from "../../images/internship_learning.jpg";
import box3 from "../../images/blue-minimalisti.jpg";
import profile from "../../images/PlacementImage.jpg";

function Internshipv2() {
  const [activeCard, setActiveCard] = useState(null);

  const navigate = useNavigate();

  const handleCardClick = (path) => {
    navigate(path);
  };

  const internships = [
    {
      title: "Python Internship",
      description:
        "Learn and apply Python in real-world scenarios while working on exciting projects. Gain hands-on experience with data analysis, web development, and automation.",
      icon: (
        <img
          src="https://www.inexture.com/wp-content/uploads/2023/08/Python-Environment-Variables-300x124-1.png"
          alt="Python Internship"
          className="card-image"
        />
      ),
      color: "#4B8BBE",
      path: "/internship/python",
    },
    {
      title: "Gen AI Internship",
      description:
        "Explore the latest advancements in Generative AI and contribute to cutting-edge AI solutions. Work on projects involving natural language processing, computer vision, and machine learning.",
      icon: (
        <img
          src="https://images.unsplash.com/photo-1677442136019-21780ecad995?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1932&q=80"
          alt="Gen AI Internship"
          className="card-image"
        />
      ),
      color: "#FF6B6B",
      path: "/internship/gen-ai",
    },
  ];

  return (
    <>
      <div className="sub-banner">
        <h1 className="h1-large">Internship</h1>
      </div>
      <div className="internship-page">
        <div className="container">
          {/* mission section */}

          <div className="mission-section">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta a
            dolor numquam quidem veniam facilis, beatae ratione, iure saepe quos
            praesentium maiores! Cupiditate saepe similique voluptatum facere
            vero totam repudiandae ab aliquam delectus voluptatem quasi eos
            laudantium vitae rerum architecto expedita perferendis id ex,
            consequatur commodi, optio voluptas cum deserunt non! Ab eum nulla
            eligendi quidem atque dolorem qui ipsam perspiciatis explicabo
            nihil, aliquam, libero recusandae commodi corporis alias fugit
            placeat illo, inventore in provident harum quo veritatis dicta esse.
            Voluptatibus saepe commodi maiores aliquam officiis distinctio
            pariatur molestias accusantium voluptate tempore dicta, fugit iusto
            amet perspiciatis numquam eum! Laudantium ut porro dolorum non
            voluptatem blanditiis est perspiciatis voluptatum eos quas
            aspernatur, veniam nulla deleniti harum asperiores sequi, expedita
            tenetur explicabo ea numquam ab illo. Et, culpa. Dolor officiis
            voluptas quidem iure unde nulla suscipit animi inventore. Laborum
            fugit fuga nemo perspiciatis totam nobis sed nihil dignissimos rem.
            Aut consequatur hic dicta
          </div>

          {/* overview section */}

          <div className="overview-section">
            <div className="text-section">
              <h1>Why join our internship program</h1>
              <p>
                As one of our interns, you will gain hands-on experience, work
                alongside professionals, and open doors to future oppurtunites{" "}
              </p>
            </div>
            <div className="points-section">
              <div className="card1 point-cards">
                <div className="card-icon">&#x2713;</div>
                <p>Gain real-world experience in Cloud and DevOps</p>
              </div>
              <div className="card2 point-cards">
                <div className="card-icon">&#x2713;</div>
                <p>Develop both technical and soft skils</p>
              </div>
              <div className="card3 point-cards">
                <div className="card-icon">&#x2713;</div>
                <p>Build a professional network with industry experts</p>
              </div>
              <div className="card4 point-cards">
                <div className="card-icon">&#x2713;</div>
                <p>
                  Potential for full-time employment upon program completion
                </p>
              </div>
            </div>
          </div>

          {/* phases section */}

          <div className="phases-section">
            <h1>Program phases</h1>
            <p>
              Our internship program is designed to provide a comprehensive
              learning experience, divided into two strategic phases that faster
              growth from foundational knowledge to advanced implementation.
            </p>
            <div className="phases-container">
              <div className="phases-box-text phases-box">
                <h4>
                  Phase 1: Learning and Growth(3 months) Kickstart Your Carrer
                  Journey
                </h4>
                <p>
                  Open to graduates in Computer Science, IT, or related fields,
                  this initial phase focuses on intensive technical training,
                  mentorship, and constructive feedback. While unpaid, this
                  phase lays the crucial groundwork for your professional
                  development.
                </p>
              </div>
              <div className="phases-box-image1 phases-box"></div>
              <div className="phases-box-image2 phases-box"></div>
              <div className="phases-box-text phases-box">
                <h4>
                  Phase 2: Professional Development (3 months) Apply Your
                  Knowledge in Real-World Scenarios
                </h4>
                <p>
                  Interns transition into a paid position. This phase emphasizes
                  practical application through shadowing experienced
                  professionals, continued mentorship, working on proof of
                  concepts, and delivering presentations. It's designed to
                  bridge the gap between theoretical knowledge and real-world
                  application.
                </p>
              </div>
            </div>
            <div className="phases-footer">
              Throughout both phases, our goal is to nurture your skills, boost
              your confidence, and prepare you for a successful career in the
              tech industry. This structured approach ensures a well-rounded
              experience that balances learning with hands-on practice.
            </div>
          </div>

          {/* cards section */}

          <section className="internship-cards">
            <h2 className="section-title">Our Opening for Internship</h2>
            <div className="cards-container">
              {internships.map((internship, index) => (
                <div
                  key={index}
                  className={`internship-card ${
                    activeCard === index ? "active" : ""
                  }`}
                  style={{ "--card-color": internship.color }}
                  onMouseEnter={() => setActiveCard(index)}
                  onMouseLeave={() => setActiveCard(null)}
                  onClick={() => handleCardClick(internship.path)}
                >
                  <div className="card-icon">{internship.icon}</div>
                  <h3 className="card-title">{internship.title}</h3>
                  <p className="card-description">{internship.description}</p>
                  <button className="card-button">
                    Apply Now <ArrowRight size={16} />
                  </button>
                </div>
              ))}
            </div>
          </section>
        </div>
        {/* stories section */}

        <div className="stories-section-wrapper">
          <div className="stories-section">
            <h2>Success Stories</h2>
            <div className="stories-grid">
              <div className="stories-card">
                <div className="stories-image-section">
                  <img className="storie-image" src={profile} alt="profile" />
                  <h5>Sophie moore</h5>
                </div>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Excepturi pariatur accusantium labore cum, nobis quod ipsam
                  quibusdam quaerat error mollitia provident nam rerum veritatis
                  rem quasi.
                </p>
              </div>
              <div className="stories-card">
                <div className="stories-image-section">
                  <img className="storie-image" src={profile} alt="profile" />
                  <h5>Sophie moore</h5>
                </div>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Excepturi pariatur accusantium labore cum, nobis quod ipsam
                  quibusdam quaerat error mollitia provident nam rerum veritatis
                  rem quasi.
                </p>
              </div>
              <div className="stories-card">
                <div className="stories-image-section">
                  <img className="storie-image" src={profile} alt="profile" />
                  <h5>Sophie moore</h5>
                </div>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Excepturi pariatur accusantium labore cum, nobis quod ipsam
                  quibusdam quaerat error mollitia provident nam rerum veritatis
                  rem quasi.
                </p>
              </div>
              <div className="stories-card">
                <div className="stories-image-section">
                  <img className="storie-image" src={profile} alt="profile" />
                  <h5>Sophie moore</h5>
                </div>
                <p>
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Excepturi pariatur accusantium labore cum, nobis quod ipsam
                  quibusdam quaerat error mollitia provident nam rerum veritatis
                  rem quasi.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Internshipv2;
