import React, { useRef, useState } from "react";
import InternshipForm from "./InternshipForm";
import { Breadcrumb, Carousel } from "react-bootstrap";

export default function PythonInternship() {
  const [activeSection, setActiveSection] = useState("overview");
  const overviewRef = useRef(null);
  const applyRef = useRef(null);

  const sections = [
    { id: "overview", title: "Overview", ref: overviewRef },
    { id: "apply", title: "Apply Now", ref: applyRef },
  ];

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  };
  const monthlyContent = [
    {
      title: "Month 1: Foundations",
      topics: ["Python basics", "Data structures", "Control flow", "Functions"],
    },
    {
      title: "Month 2: Advanced Concepts",
      topics: ["OOP", "File handling", "APIs", "Libraries"],
    },
    {
      title: "Month 3: Real-world Projects",
      topics: [
        "Web scraping",
        "Databases",
        "Flask/Django",
        "Data visualization",
      ],
    },
  ];

  const testimonials = [
    {
      quote:
        "This internship kickstarted my career in tech industry. The hands-on experience was invaluable!",
      name: "Sarah J.",
      position: "Now at Tech Giant Inc.",
    },
    {
      quote:
        "I learned so much in such a short time! The projects helped me understand real-world applications.",
      name: "John D.",
      position: "Software Developer at StartUp",
    },
    {
      quote:
        "The support and mentorship were top-notch. I couldn't have asked for a better learning experience.",
      name: "Emma K.",
      position: "Python Engineer at Innovate Ltd.",
    },
    {
      quote:
        "Thanks to this internship, I was able to switch careers into tech. The journey was tough but rewarding!",
      name: "Michael B.",
      position: "Backend Developer at Webify",
    },
  ];

  return (
    <>
      <div className="sub-banner">
        <Breadcrumb className="internship-breadcrumb">
          <Breadcrumb.Item href="/internship">Internship</Breadcrumb.Item>
          <Breadcrumb.Item active>Python internship</Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="h1-large">Internship</h1>
      </div>
      <div className="python-internship-page">
        <h1 className="internship-heading">Python Internship Program</h1>
        <header className="internship-header">
          <h1>Master Python in 6 months with hands-on projects</h1>
        </header>

        <nav className="nav-menu">
          {sections.map((section) => (
            <button
              key={section.id}
              className={`intern-nav-item ${
                activeSection === section.id ? "active" : ""
              }`}
              onClick={() => {
                setActiveSection(section.id);
                scrollToSection(section.ref);
              }}
            >
              {section.title}
            </button>
          ))}
        </nav>

        <main className="main-content">
          <div className="overview-description">
            <p>
              Our Python Internship is designed to transform beginners into
              job-ready Python developers. Through hands-on projects, expert
              mentorship, and a curriculum aligned with industry needs, you'll
              gain the skills and confidence to launch your career in tech.
            </p>
          </div>
          <section ref={overviewRef} className="overview-con">
            <h2>Program Highlights</h2>
            <div className="highlights-grid">
              <div className="highlight-item">
                <span className="highlight-number">24/7</span>
                <span className="highlight-text">Support</span>
              </div>
              <div className="highlight-item">
                <span className="highlight-number">4.7</span>
                <span className="highlight-text">Rating</span>
              </div>
              <div className="highlight-item">
                <span className="highlight-number">96%</span>
                <span className="highlight-text">Satisfaction Rate</span>
              </div>
              <div className="highlight-item">
                <span className="highlight-number">1000+</span>
                <span className="highlight-text">Students Enrolled</span>
              </div>
            </div>
          </section>
        </main>

        {/* Python Internship Phases */}
        <div className="phases-con">
          <h3 className="phase-title">Internship Phases</h3>
          <div className="phase-content">
            <p className="phase-details">
              <strong>
                Phase 1: Foundations and Growth (3 months)
                <br /> Master Python Essentials
              </strong>
              <br />
              This initial phase focuses on learning Python fundamentals, such
              as data structures, OOP, and libraries like Pandas and Flask. It
              also includes mentorship and feedback sessions. This phase is
              unpaid but provides crucial hands-on learning experiences that lay
              the foundation for your Python development journey.
            </p>
            <p className="phase-details">
              <strong>
                Phase 2: Professional Development (3 months)
                <br /> Apply in Real-World Projects
              </strong>
              <br />
              Interns transition into a paid position. In this phase, you'll
              apply your Python skills in real-world scenarios, including
              working on projects involving web scraping, databases, APIs, and
              data visualization. You'll continue to receive mentorship and work
              alongside industry professionals to bring your knowledge to life
              through practical application.
            </p>
            <p className="phase-conclusion">
              Throughout both phases, the program is designed to nurture your
              Python skills, boost your confidence, and prepare you for a
              successful tech career. The combination of learning and hands-on
              practice ensures a well-rounded and impactful internship
              experience.
            </p>
          </div>
        </div>

        <footer className="footer">
          <div className="testimonial">
            <Carousel>
              {testimonials.map((testimonial, index) => (
                <Carousel.Item key={index}>
                  <blockquote>"{testimonial.quote}"</blockquote>-{" "}
                  {testimonial.name}, {testimonial.position}
                  <footer></footer>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>

          <div className="apply-main">
            <div className="stats">
              <div className="stat-item">
                <span className="stat-number">500+</span>
                <span className="stat-label">Interns</span>
              </div>
              <div className="stat-item">
                <span className="stat-number">90%</span>
                <span className="stat-label">Placements</span>
              </div>
              <div className="stat-item">
                <span className="stat-number">88%</span>
                <span className="stat-label">Certification Rate</span>
              </div>
              <div className="stat-item">
                <span className="stat-number">50+</span>
                <span className="stat-label">Partner Companies</span>
              </div>
            </div>
            <section ref={applyRef} className="apply-section">
              <h2>Start Your Journey</h2>
              <InternshipForm internshipType="python" />
            </section>
          </div>
        </footer>
      </div>
    </>
  );
}
