import React, { useRef, useState } from "react";
import InternshipForm from "./InternshipForm";
import { Breadcrumb, Carousel } from "react-bootstrap";

export default function GenAIInternshipPage() {
  const [activeSection, setActiveSection] = useState("overview");
  const overviewRef = useRef(null);
  const applyRef = useRef(null);

  const sections = [
    { id: "overview", title: "Overview", ref: overviewRef },
    { id: "apply", title: "Apply Now", ref: applyRef },
  ];

  const scrollToSection = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const monthlyContent = [
    {
      title: "Month 1: Introduction to Generative AI",
      topics: [
        "Understanding Generative AI",
        "Basics of machine learning and deep learning",
        "Introduction to neural networks",
        "Using pre-trained AI models",
      ],
    },
    {
      title: "Month 2: Advanced Generative Techniques",
      topics: [
        "Generative Adversarial Networks (GANs)",
        "Transformer models (e.g., GPT)",
        "Fine-tuning AI models",
        "Text generation with GPT models",
      ],
    },
    {
      title: "Month 3: Working with GPT and DALL·E",
      topics: [
        "Implementing GPT for text-based applications",
        "Image generation using DALL·E",
        "Exploring multimodal models (text and image)",
        "Creating AI-driven applications",
      ],
    },
    {
      title: "Months 4-6: AI Application Development and Research",
      topics: [
        "Building AI applications",
        "Researching and experimenting with new models",
        "Collaborative projects",
        "Capstone project: AI-driven application",
      ],
    },
  ];

  const testimonials = [
    {
      quote:
        "The Generative AI Internship opened doors for me. I gained hands-on experience and built a strong AI portfolio.",
      name: "Alice M.",
      position: "AI Researcher at AI Labs",
    },
    {
      quote:
        "This internship gave me the opportunity to work on exciting AI projects. The mentorship was invaluable.",
      name: "Brian T.",
      position: "Machine Learning Engineer at TechHub",
    },
    {
      quote:
        "Working with cutting-edge AI tools like GPT and DALL·E was a transformative experience.",
      name: "Clara S.",
      position: "AI Developer at FutureTech",
    },
    {
      quote:
        "This internship allowed me to apply my knowledge to real-world AI projects. It was challenging but incredibly rewarding!",
      name: "David R.",
      position: "AI Engineer at InnovateAI",
    },
  ];

  return (
    <>
      <div className="sub-banner">
        <Breadcrumb className="internship-breadcrumb">
          <Breadcrumb.Item href="/internship">Internship</Breadcrumb.Item>
          <Breadcrumb.Item active>Generative AI internship</Breadcrumb.Item>
        </Breadcrumb>
        <h1 className="h1-large">Internship</h1>
      </div>
      <div className="genai-internship-page">
        <h1 className="internship-heading">Generative AI Internship Program</h1>
        <header className="internship-header">
          <h1>Master Generative AI in 6 months with hands-on projects</h1>
        </header>
        <nav className="nav-menu">
          {sections.map((section) => (
            <button
              key={section.id}
              className={`intern-nav-item ${
                activeSection === section.id ? "active" : ""
              }`}
              onClick={() => {
                setActiveSection(section.id);
                scrollToSection(section.ref);
              }}
            >
              {section.title}
            </button>
          ))}
        </nav>

        <main className="main-content">
          <div className="overview-description">
            <p>
              Our Generative AI Internship is designed to equip you with the
              skills to work with cutting-edge AI models like GPT and DALL·E.
              With a focus on hands-on projects and real-world AI applications,
              this internship prepares you for a career in AI development and
              research.
            </p>
          </div>
          <section ref={overviewRef} className="overview-con">
            <h2>Program Highlights</h2>
            <div className="highlights-grid">
              <div className="highlight-item">
                <span className="highlight-number">24/7</span>
                <span className="highlight-text">Support</span>
              </div>
              <div className="highlight-item">
                <span className="highlight-number">4.8</span>
                <span className="highlight-text">Rating</span>
              </div>
              <div className="highlight-item">
                <span className="highlight-number">94%</span>
                <span className="highlight-text">Satisfaction Rate</span>
              </div>
              <div className="highlight-item">
                <span className="highlight-number">500+</span>
                <span className="highlight-text">Students Enrolled</span>
              </div>
            </div>
          </section>
        </main>

        {/* Generative AI Internship Phases */}
        <div className="phases-con">
          <h3 className="phase-title">Internship Phases</h3>
          <div className="phase-content">
            <p className="phase-details">
              <strong>Phase 1: Generative AI Foundations (3 months)</strong>
              <br />
              Dive into the world of AI by understanding its fundamentals and
              learning how to work with pre-trained models like GPT and DALL·E.
              This phase focuses on building a strong foundation in machine
              learning, deep learning, and generative models.
            </p>
            <p className="phase-details">
              <strong>Phase 2: Real-World AI Applications (3 months)</strong>
              <br />
              Transition into real-world applications of generative AI by
              developing text generation models and creating image-based AI
              applications. You'll work on practical projects while receiving
              mentorship to refine your skills.
            </p>
            <p className="phase-conclusion">
              By the end of the internship, you'll have the expertise to build
              your own AI-driven applications and the confidence to enter the AI
              industry with practical experience and knowledge.
            </p>
          </div>
        </div>

        <footer className="footer">
          <div className="testimonial">
            <Carousel>
              {testimonials.map((testimonial, index) => (
                <Carousel.Item key={index}>
                  <blockquote>"{testimonial.quote}"</blockquote>-{" "}
                  {testimonial.name}, {testimonial.position}
                  <footer></footer>
                </Carousel.Item>
              ))}
            </Carousel>
          </div>

          <div className="apply-main">
            <div className="stats">
              <div className="stat-item">
                <span className="stat-number">500+</span>
                <span className="stat-label">Interns</span>
              </div>
              <div className="stat-item">
                <span className="stat-number">92%</span>
                <span className="stat-label">Placements</span>
              </div>
              <div className="stat-item">
                <span className="stat-number">90%</span>
                <span className="stat-label">Certification Rate</span>
              </div>
              <div className="stat-item">
                <span className="stat-number">40+</span>
                <span className="stat-label">Partner Companies</span>
              </div>
            </div>
            <section ref={applyRef} className="apply-section">
              <h2>Start Your Journey</h2>
              <InternshipForm internshipType="genAI" />
            </section>
          </div>
        </footer>
      </div>
    </>
  );
}
