import React, { useState, useRef } from "react";
import Select from "react-select";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const InternshipForm = ({ internshipType }) => {
  const initialFormData = {
    name: "",
    college_name: "",
    mobile_number: "",
    message: "",
    resume: null,
    role: null,
    graduation_year: null,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);

  const roleOptions = [
    { value: "student", label: "Student" },
    { value: "fresher", label: "Fresher" },
    { value: "workingProfessional", label: "Working Professional" },
  ];

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: "10px",
    }),
  };

  const graduationYearOptions = Array.from({ length: 10 }, (_, i) => ({
    value: 2020 + i,
    label: 2020 + i,
  }));

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleResumeChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        // 5MB in bytes
        toast.error(
          "File size exceeds 5MB limit. Please choose a smaller file."
        );
        e.target.value = null; // Reset the file input
      } else {
        setFormData({ ...formData, resume: file });
      }
    }
  };

  const handleRoleChange = (selectedOption) => {
    setFormData({ ...formData, role: selectedOption });
  };

  const handleGraduationYearChange = (selectedOption) => {
    setFormData({ ...formData, graduation_year: selectedOption });
  };

  const resetForm = () => {
    setFormData(initialFormData);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate mobile number length
    if (formData.mobile_number.length !== 10) {
      toast.error("Mobile number must be exactly 10 digits.");
      return;
    }

    setIsLoading(true);

    const formSubmission = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key === "role" || key === "graduation_year") {
        formSubmission.append(key, value ? value.value : "");
      } else {
        formSubmission.append(key, value);
      }
    });
    formSubmission.append("internship", internshipType);

    try {
      const response = await axios.post(
        process.env.REACT_APP_API_BASE_URL + "/v1/apply",
        formSubmission,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Channel": "app",
          },
        }
      );
      console.log("Form submitted successfully:", response.data);
      toast.success("Application submitted successfully!");
      resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Error submitting application. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="internship-form-container">
      <form onSubmit={handleSubmit} className="internship-form">
        <div className="form-grid">
          <div className="form-group">
            <label htmlFor="name">
              Full Name<span className="formstar">*</span>
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              required
              placeholder="Your full name"
            />
          </div>
          <div className="form-group">
            <label htmlFor="mobile_number">
              Mobile Number<span className="formstar">*</span>
            </label>
            <input
              type="number"
              inputMode="numeric"
              pattern="\d{10}"
              maxLength={10}
              id="mobile_number"
              name="mobile_number"
              value={formData.mobile_number}
              onChange={handleInputChange}
              required
              placeholder="Your mobile number"
            />
          </div>
          <div className="form-group">
            <label htmlFor="role">
              Role<span className="formstar">*</span>
            </label>
            <div className="select-wrapper">
              <Select
                styles={customStyles}
                id="role"
                name="role"
                options={roleOptions}
                onChange={handleRoleChange}
                value={formData.role}
                placeholder="Select Current Role"
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="graduation_year">
              Year of Graduation<span className="formstar">*</span>
            </label>
            <div className="select-wrapper">
              <Select
                styles={customStyles}
                id="graduation_year"
                name="graduation_year"
                options={graduationYearOptions}
                onChange={handleGraduationYearChange}
                value={formData.graduation_year}
                placeholder="Select Graduation Year"
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="college_name">
            College Name<span className="formstar">*</span>
          </label>
          <input
            type="text"
            id="college_name"
            name="college_name"
            value={formData.college_name}
            onChange={handleInputChange}
            required
            placeholder="Your college name"
          />
        </div>
        <div className="form-group">
          <label htmlFor="resume">
            Upload Resume <span className="span1"> (Max 5MB)</span>
            <span className="formstar">*</span>
          </label>
          <div className="file-input-wrapper">
            <input
              type="file"
              id="resume"
              name="resume"
              accept=".pdf"
              onChange={handleResumeChange}
              required
              ref={fileInputRef}
            />
            <span className="file-input-text">
              {formData.resume ? formData.resume.name : "Choose file"}
            </span>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="message">
            Message<span className="formstar">*</span>
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            rows="4"
            placeholder="Tell us why you're interested in this internship."
          ></textarea>
        </div>
        <button type="submit" className="submit-button" disabled={isLoading}>
          {isLoading ? "Submitting..." : "Submit Application"}
        </button>
      </form>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={true}
      />
    </div>
  );
};

export default InternshipForm;
